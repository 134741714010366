<template>
  <el-dialog
    title="客服消息详情"
    :visible.sync="show"
    center
    width="700px"
    top="2vh"
    append-to-body
    :before-close="handleClose"
  >
    <div class="service-detail">
      <el-form label-width="120px" class="form-container">
        <el-form-item label="公众号：">
          <span>{{ form.channelName }}</span>
        </el-form-item>
        <el-form-item label="备注：">
          <span>{{ form.remark || "无" }}</span>
        </el-form-item>
        <el-form-item label="消息类型：">
          <el-tag v-if="form.msgtype === 'text'" type="info">文本</el-tag>
          <el-tag
            v-else-if="form.msgtype === 'news' || form.msgtype === 'mpnews'"
            >图文</el-tag
          >
          <el-tag v-else-if="form.msgtype === 'voice'" type="success"
            >音频</el-tag
          >
          <el-tag v-else-if="form.msgtype === 'image'" type="success"
            >图片</el-tag
          >
          <el-tag v-else-if="form.msgtype === 'miniprogrampage'" type="success"
            >小程序卡片</el-tag
          >
          <el-tag v-else type="danger">未知</el-tag>
        </el-form-item>
        <el-form-item label="消息内容：">
          <div class="div-highlights" v-if="form.msgtype === 'text'">
            {{ textStr }}
          </div>
          <div v-if="form.msgtype === 'mpnews'">
            <div class="material-item" v-if="material">
              <ul class="article-box">
                <template v-if="material.title.includes('[{')">
                  <li
                    class="article-item"
                    v-for="(item2, index2) in JSON.parse(material.title)"
                    :key="index2"
                  >
                    <p>{{ item2.title }}</p>
                    <img :src="item2.url" />
                  </li>
                </template>
                <template v-else>
                  <span>{{ material.title }}</span>
                </template>
              </ul>
            </div>
          </div>
          <el-form v-if="form.msgtype === 'news'">
            <el-form-item label="链接">
              <span style="margin-right: 10px">{{ form.novelName }}</span>
            </el-form-item>
            <el-form-item>
              <div class="example">
                <div class="card">
                  <p class="mesg-title">{{ newsForm.title || "消息标题" }}</p>
                  <div class="detail">
                    <p>{{ newsForm.description || "描述文字" }}</p>
                    <template>
                      <img
                        :src="newsForm.picurl"
                        alt=""
                        v-if="newsForm.picurl"
                      />
                      <div class="image-slot" v-else>
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
          <p v-if="form.msgtype === 'voice'">{{ selectVoice.title || "无" }}</p>
          <template v-if="form.msgtype === 'image'">
            <span v-if="selectImage.title.includes('{')">
              <p v-if="form.msgtype === 'image'">
                {{ JSON.parse(selectImage.title).title || "无" }}
              </p>
              <img
                style="max-width: 200px; max-height: 200px"
                :src="JSON.parse(selectImage.title).url"
                alt=""
              />
            </span>
            <span v-else>
              <p>{{ selectImage.title || "无" }}</p>
            </span>
          </template>
          <div v-if="form.msgtype === 'miniprogrampage'">
            <img
              style="max-width: 100px; max-height: 100px"
              :src="miniForm.novelName"
              alt=""
            />
            <p>
              <span>标题：</span>
              <span>{{ miniForm.title }}</span>
            </p>
            <p>
              <span>appid：</span>
              <span>{{ miniForm.appid }}</span>
            </p>
            <p>
              <span>页面路径：</span>
              <span>{{ miniForm.pagepath }}</span>
            </p>
          </div>
        </el-form-item>
        <el-form-item label='订单金额：'>
         <span>{{form.minAmount}}元 ~ {{form.maxAmount}}元</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'edit',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    account: {
      type: Array,
      default: () => []
    },
    info: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      testId: null,
      estimateNum: null,
      form: {},
      textStr: '', // 文本内容
      textIndex: 0,
      insertBook: false,
      newsForm: {},
      formLoading: false,
      selectVoice: {},
      selectImage: {},
      showFlag: false,
      timeoutObj: {
        hour: 0,
        minute: 0,
        second: 0
      },
      miniForm: {}
    }
  },
  computed: {
    ...mapGetters(['officialList'])
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal && this.info) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods: {
    initData () {
      const {
        content,
        accountChannelId,
        channelName,
        novelName,
        remark,
        minAmount,
        maxAmount,
      } = this.info

      const defaultAccount = this.account.find(
        (item) => item.id === accountChannelId
      )
      this.selectChannel = {
        id: accountChannelId,
        channelName: channelName,
        platform: defaultAccount ? defaultAccount.platform : null
      }
      this.form = {
        msgtype: this.info.msgtype,
        remark,
        channelName,
        minAmount,
        maxAmount
      }

      const contentObj = JSON.parse(content)
      if (this.form.msgtype === 'text') {
        this.textStr = contentObj.content
      } else if (this.form.msgtype === 'news') {
        this.$set(this.form, 'novelName', novelName)
        this.newsForm = contentObj.articles[0]
      } else if (this.form.msgtype === 'voice') {
        this.selectVoice = {
          mediaId: contentObj.media_id,
          title: novelName
        }
      } else if (this.form.msgtype === 'image') {
        this.selectImage = {
          mediaId: contentObj.media_id,
          title: novelName.includes('{') ? JSON.parse(novelName).title : novelName,
          url: novelName.includes('{') ? JSON.parse(novelName).url : null
        }
      } else if (this.form.msgtype === 'miniprogrampage') {
        this.miniForm = {
          novelName: novelName,
          title: contentObj.title,
          appid: contentObj.appid,
          pagepath: contentObj.pagepath
        }
      }
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
  overflow: hidden;
}
/deep/ .el-dialog__body {
  padding-bottom: 0;
}

.service-detail {
  box-sizing: border-box;
  padding: 20px;
  height: 700px;
  overflow: auto;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  // margin-top:20px;
  text-align: center;
}
.form-container {
  max-height: 800px;
  // overflow: auto;
  padding-right: 10px;
}

.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.detail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}
.div-highlights {
  margin-top: 10px;
  width: auto !important;
  height: auto !important;
  border-color: transparent !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  // color: transparent !important;
  color: #000;
  line-height: 1;
  // overflow: hidden !important;
}

// 图文回显
.material-item {
  margin-top: 5px;
  width: 256px;
  height: 380px;
  padding: 10px;
  margin-right: 20px;
  box-shadow: 0 1px 6px 0 #ccc;
  border-radius: 5px;
  position: relative;
  .article-box {
    position: relative;
    .article-item:first-child {
      width: 236px;
      height: 100px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        margin-left: 0;
      }
      p {
        position: absolute;
        bottom: 5px;
        left: 5px;
        width: 226px;
        height: 30px;
        line-height: 30px;
        padding-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    .article-item {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 30px;
        height: 30px;
        background: #666;
        margin-left: 15px;
      }
      p {
        flex: 1;
        display: table-cell;
        vertical-align: middle;
        white-space: normal;
        word-break: break-all;
        line-height: 30px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        font-size: 14px;
      }
    }
  }
}
</style>
