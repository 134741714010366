<template>
  <div
    class="untopped-order-msg border-radius-6 box-shadow-light-grey padding-20 height-calc-type1"
  >
    <div class="untopped-order-msg-container">
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        :loading="loading"
        :showPagination="true"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        :isUseCheckbox="true"
        ref="mainTable"
        refName="subTable"
        @pageChange="pageChange"
        @selection-change="selectChange"
        @handleRowClick="handleRowClick2"
        height="67vh"
      >
        <template #filter>
          <div class="flex-row-start-start">
            <el-button @click="initData">刷新数据</el-button>
            <c-button
              @click="cb => { batchCopy(cb) }"
              type="warning"
              :disabled="!currentOfficial || select.length === 0"
            >批量复制</c-button>
            <c-button
              :autoConfirm="true"
              @confirm="(cb) => { batchDelMsg(cb) }"
              type="danger"
              style="margin-right:10px"
              :disabled="select.length === 0"
            >批量删除消息</c-button>
            <el-button
              @click="addMsg"
              type="success"
              style="margin-right:10px;margin-left:auto"
              icon="el-icon-plus"
            >新增客服消息</el-button>
          </div>
        </template>
        <template #status="{ row }">
          <el-switch
            v-model="row.status"
            :active-value="1"
            :inactive-value="2"
            @change="(status) => { handleChangeSwitch(status, row.id) }"
          ></el-switch>
        </template>
        <template #msgtype="{ row }">
          <el-tag v-if="row.msgtype === 'text'" type="info">文本</el-tag>
          <el-tag v-else-if="row.msgtype === 'news'">图文</el-tag>
          <el-tag v-else-if="row.msgtype === 'voice'" type="success">音频</el-tag>
          <el-tag v-else-if="row.msgtype === 'image'" type="success">图片</el-tag>
          <el-tag v-else-if="row.msgtype === 'video'" type="success">视频</el-tag>
          <el-tag v-else-if="row.msgtype === 'miniprogrampage'" type="success">小程序卡片</el-tag>
          <el-tag v-else type="danger">未知</el-tag>
        </template>
        <template #operation="{ row }">
          <el-tooltip class="item" effect="dark" content="详情" placement="top-start">
            <el-button
              type="info"
              icon="el-icon-document"
              circle
              @click="handleDialogShow(row, 'showDetail')"
            ></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="编辑" placement="top-start">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="handleDialogShow(row, 'isEdit')"
            ></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="复制" placement="top-start">
            <el-button
              type="warning"
              icon="el-icon-document-copy"
              circle
              @click="handleRowClick('copy', row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleRowClick('delete', row)"
            ></el-button>
          </el-tooltip>
        </template>
      </dy-table>
    </div>
    <el-dialog
      title="复制"
      :visible.sync="showCopyDialog"
      append-to-body
      center
      width="400px"
      v-dialogDrag
    >
      <el-select
        style="width: 350px"
        v-model="selAccountList"
        placeholder="请选择公众号!"
        clearable
        filterable
        multiple
      >
        <el-option
          v-for="item in getFilterList()"
          :key="item.id"
          :label="item.channelName"
          :value="item.id"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span
            style="float: right; margin-left: 15px; font-size: 13px"
          >{{ item.platformStr }} - {{ item.uname }}</span>
        </el-option>
      </el-select>
      <div class="button-list">
        <el-button type="primary" @click="handleOk" :loading="copyLoading">确定</el-button>
        <el-button @click="(selAccountList = [], showCopyDialog = false, id = null)">取消</el-button>
      </div>
    </el-dialog>
    <msg-detail :show="showDetail" :info="curInfo" @close="showDetail = false"></msg-detail>
    <add-msg
      :show="showAddDialog"
      :info="curInfo"
      :account="channelList"
      :isEdit="isEdit"
      :keyId='currentOfficial'
      @refresh="initData"
      @close="showAddDialog = false"
    ></add-msg>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUnPaidMegList, delUnPaidMsg, editUnPaidStatus, copyUnPaidMsg } from '@/api/classify'
import {
  messagePlatformList
} from '@/assets/js/options'
import MsgDetail from './msg-detail.vue'
import AddMsg from './add-msg.vue'

export default {
  props: {
    currentOfficial: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      page: 0,
      pageSize: 15,
      total: 0,
      loading: false,
      dataList: [],
      copyMessage: [],
      isCopy: false,
      isEdit: false,
      select: [],
      id: null,
      selAccountList: [],
      showAddDialog: false,
      showDetail: false,
      curInfo: null,
      keyId: null, // 传递给新增或者回显的公众号id
      copyLoading: false,
      showCopyDialog: false,
      rowList: [{
        label: '列表ID',
        prop: 'id'
      }, {
        label: '公众号',
        prop: 'channelName'
      }, {
        label: '备注',
        prop: 'remark'
      }, {
        label: '消息类型',
        prop: 'msgtype',
        slot: true
      }, {
        label: '状态',
        prop: 'status',
        slot: true
      }, {
        label: '最小订单金额',
        prop: 'minAmount'
      }, {
        label: '最大订单金额',
        prop: 'maxAmount'
      }, {
        label: '创建时间',
        prop: 'createdTime',
        width: 150
      }, {
        label: '操作',
        action: 'operation',
        width: '150'
      }]
    }
  },
  computed: {
    ...mapGetters(['officialList', 'userInfo']),
    channelList () {
      const { officialList } = this
      return officialList.filter(
        item => messagePlatformList.includes(item.platform) && item.status === 1
      )
    }
  },
  methods: {
    initData () {
      this.loading = true
      getUnPaidMegList({
        page: this.page,
        pageSize: this.pageSize,
        accountChannelId: this.currentOfficial
      }).then((res) => {
        this.total = res.total
        this.dataList = res.list
      }).finally(() => {
        this.loading = false
      })
    },
    pageChange (page) {
      this.page = page
      this.initData()
    },
    addMsg () {
      this.isEdit = false
      this.curInfo = null
      this.showAddDialog = true
    },
    selectChange (select) {
      this.select = select
    },
    batchDelMsg (cb) {
      const ids = this.select.map(item => item.id).join(',')
      delUnPaidMsg(ids).then(() => {
        this.$message.success('删除成功！')
        this.initData()
      }).finally(() => {
        cb()
      })
    },
    handleRowClick (type, data) {
      if (type === 'delete') {
        this.handleDelete([data.id])
      } else if (type === 'copy') {
        this.curInfo = data
        this.showCopyDialog = true
      }
    },
    handleDialogShow (item, prop) {
      this[prop] = true
      this.curInfo = item
      if (prop === 'isEdit') {
        this.showAddDialog = true
      }
    },
    handleChangeSwitch (status, id) {
      editUnPaidStatus(id, status).then(() => {
        this.initData()
      })
    },
    handleDelete (ids) {
      this.$confirm('确定删除吗？删除的数据无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUnPaidMsg(ids.join(',')).then(() => {
          this.$message.success('删除成功!')
          this.initData()
        })
      }).catch(() => { })
    },
    handleRowClick2 (row, column, event) {
      this.$refs.mainTable.$refs.subTable.toggleRowSelection(row)
    },
    batchCopy (cb) {
      cb()
      this.showCopyDialog = true
      this.curInfo = null
    },
    handleOk () {
      if (this.selAccountList.length === 0) {
        return this.$message.warning('请选择公众号!')
      }
      this.copyLoading = true
      copyUnPaidMsg(this.curInfo?.id || this.select.map(item => item.id).join(','), {
        accountChannelIds: this.selAccountList
      })
        .then(() => {
          this.$message.success('复制成功!')
          this.showCopyDialog = false
          this.selAccountList = []
          this.id = null
          this.initData()
        })
        .finally(() => {
          this.copyLoading = false
        })
    },
    getFilterList () {
      const target = this.channelList.find(item => item.id === this.curInfo?.accountChannelId)
      if (target) {
        return this.channelList.filter(item => item.platform === target.platform)
      }
      return this.channelList
    }

  },
  components: { MsgDetail, AddMsg }
}
</script>
<style lang='scss' scoped>
.untopped-order-msg {
  background: #fff;
}
.button-list {
  text-align: center;
  margin-top: 50px;
}
</style>
